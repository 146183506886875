<template>
    <form @submit.prevent="handleSubmit" class="p-5 md:p-20 lg:px-80 xl:px-96">
        <div class=" p-5 isolate space-y-1 rounded-md shadow-sm">
            <h1 class="text-4xl mb-5">Login</h1>
            <div class=" relative border border-gray-300 rounded-md rounded-b-none px-3 py-2 focus-within:z-10 focus-within:ring-1" :class="`focus-within:${ringbrand600} focus-within:${borderbrand600}`">
            <label for="name" class="block text-xs font-medium text-gray-700">Email</label>
            <input type="email" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm" :placeholder="`joe@${url}`" v-model="email" />
            </div>
            <div class="mb-5 relative border border-gray-300 rounded-md rounded-t-none px-3 py-2 focus-within:z-10 focus-within:ring-1" :class="`focus-within:${ringbrand600} focus-within:${borderbrand600}`">
            <label for="job-title" class="block w-full text-xs font-medium text-gray-700">Password</label>
            <input type="password" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm" placeholder="********" v-model="password" />
            </div>
            <div v-if="error"> 
                {{ error }}
            </div>
            <div class="mt-5 flex">
                <button v-if="!isPending" class=" inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 " :class="`${bgbrand600} hover:${bgbrand700} focus:${ringbrand600}`">Login</button>
                <button v-if="isPending" class=" inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 " :class="`${bgbrand600} hover:${bgbrand700} focus:${ringbrand600}`">
                    Loading
                </button>
                <div class="ml-5">
                    <router-link :to="{name: 'ForgotPassword'}">
                        Forgot password?
                    </router-link>
                    
                </div>
            </div>
            
        </div>
    </form>
    

</template>

<script>
import useLogin from '@/composables/useLogin'
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import configSetup from '@/setup.js'

export default {
    created() {
        const {company} = configSetup()
        document.title = `${company} | Login`
},

    setup() {
        const {ringbrand600, url, borderbrand600, bgbrand600, bgbrand700} = configSetup()

        const {error, login, isPending} = useLogin();
        const router = useRouter()

        const email = ref('')
        const password = ref('')


        const handleSubmit = async () => {
            const res = await login(email.value, password.value)
            
            if (!error.value) {
                console.log('User logged in')
                router.push({name: 'ImageLoader'})
                }
            }

            return {email, password, handleSubmit, error, isPending, ringbrand600, url, borderbrand600, bgbrand600, bgbrand700}

    }
}
</script>