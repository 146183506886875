import { ref } from 'vue'
import { projectAuth } from '../firebase/config'

const error = ref(null)
const isPending = ref(false)
const success = ref(null)

const login = async (email, password) => {
  error.value = null
  isPending.value = true

  try {
    const res = await projectAuth.signInWithEmailAndPassword(email, password)
    error.value = null
    isPending.value = false
    return res
  } catch (err) {
    error.value = 'Incorrect login credentials'
    isPending.value = false
  }
}

const resetPassword = async (email) => {
  error.value = null
  isPending.value = true
  

  try {
    const res = await projectAuth.sendPasswordResetEmail(email)
    error.value = null
    isPending.value = false
    success.value = `Password reset email has been sent to ${email}`
    return res
  } catch (err) {
    error.value = 'That email does not exist'
    isPending.value = false
  }
}

const useLogin = () => {
  return { error, login, isPending, resetPassword, success }
}

export default useLogin
